var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "section section--fullScreen section--flexCenter" },
    [
      _c(
        "router-link",
        { staticClass: "modalcloseButton", attrs: { to: { name: "Browse" } } },
        [_c("icon", { attrs: { name: "close" } })],
        1
      ),
      _vm.step === 1
        ? _c("div", { staticClass: "container container--xxs" }, [
            _c(
              "form",
              {
                staticClass: "form",
                attrs: { name: "password-reset", autocomplete: "off" },
              },
              [
                _vm._m(0),
                _c("div", { staticClass: "formGroup" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.password,
                        expression: "password",
                      },
                    ],
                    staticClass: "formInput formInput--fullWidth",
                    attrs: {
                      type: "password",
                      name: "password",
                      placeholder: "Enter New Password",
                      autocomplete: "new-password",
                      required: "",
                    },
                    domProps: { value: _vm.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.password = $event.target.value
                      },
                    },
                  }),
                ]),
                _vm.error
                  ? _c("p", { staticClass: "form__error" }, [
                      _vm._v(_vm._s(_vm.error.toString())),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "form__submit" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn--fullWidth btn--primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetPasswordConfirm.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Reset password")]
                  ),
                ]),
              ]
            ),
          ])
        : _c(
            "div",
            { staticClass: "container container--xxs" },
            [
              _c("h1", [_vm._v("Success!")]),
              _c("p", [_vm._v("You can now login to your account.")]),
              _c(
                "router-link",
                {
                  staticClass: "btn btn--fullWidth btn--primary",
                  attrs: { to: { name: "Login" } },
                },
                [_vm._v("LOGIN")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form__title" }, [
      _c("h3", [_vm._v("Reset password")]),
      _c("p", { staticClass: "p--lead" }, [
        _vm._v(
          "Enter your new password to reset it and press the button below."
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }