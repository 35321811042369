























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'
import { resetPasswordConfirm } from '~/api/auth'
import { INotificationParams, ModalStore } from '~/store/modal'
import { prettifyApiError } from '~/services/prettify-api-error'

@Component
export default class PasswordResetPage extends Vue {
    @Prop({ type: String }) uidb64: string
    @Prop({ type: String }) token: string

    password = ''
    error = ''
    step = 1

    @ModalStore.Action('showNotification') showNotification: (params: INotificationParams) => void

    async resetPasswordConfirm() {
        const dispatchParams = { uid: this.uidb64, token: this.token, new_password1: this.password, new_password2: this.password }
        try {
            await resetPasswordConfirm(dispatchParams)
            this.step++
        } catch (err) {
            this.error = prettifyApiError(err)
            this.showNotification({ name: 'error', title: 'Something went wrong!', content: 'Please try again later, or contact support' })
        }
    }
}
